.summary {
  height: 554px;
  overflow: auto;
  &__item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &__price {
    font-weight: 500;
    font-size: 14px;
    line-height: 138%;
    color: $text;
    border-top: 0.5px solid $disabled;
    border-bottom: 0.5px solid $disabled;
    padding: 16px 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  &__files-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  &__file {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: #f4f4f4;
    padding: 10px 16px;
    border-radius: 4px;
    z-index: 1;
    gap: 12px;
    .svg--file{
      width: 20.5px;
      height: 24.6px;
    }
  }
  &__files-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  &__badge {
    border-radius: 20px;
    background-color: $background1;
    padding: 2px 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 8px;
    align-self: start;
    @include body2;
    color: $text;
    .svg {
      height: 13px;
      width: 17px;
    }
  }
  &__languages {
    display: flex;
    gap: 6px;
    .svg--arrow-right {
      margin-top: 3px;
    }
  }
  &__languages-item {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
  }
  &__incorrect-format {
    display: flex;
    flex-direction: column;
    .summary__file{
      margin-bottom: 8px
    }
  }
  &__incorrect-format-header {
    @include body1;
    color: $gray800;
  }
  &__incorrect-format-description {
    @include body2;
    color: $gray600;
    margin-top: 3px;
    margin-bottom: 16px;
  }
}
