@import './components/LanguageForm.scss';
@import './components/ServiceLevels.scss';
@import './components/Summary.scss';

.order-page {
  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include lg {
      flex-direction: row;
      gap: 16px;
    }
    .select__wrapper:not(:last-child),
    .radio__wrapper:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  &__file-input {
    display: none;
  }
  &__header {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: $black;
    &--right {
      margin-bottom: 25px;
      @include lg {
        margin-bottom: 35px;
      }
    }
  }
  &__translateTo-wrapper {
    display: flex;
    width: 100%;
    position: relative;
    .select__multi-value:nth-child(n + 3) {
      display: none;
    }
    &--multi{
      .select__indicator{
        display: none;
      }
    }
  }
  &__translateTo-indicator {
    position: absolute;
    right: 16px;
    top: 35px;
    @include body1;
  }
  &__subheader {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $textColor;
    margin-bottom: 24px;
  }
  &__text-below-button {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: $gray-1;
    margin-top: 16px;
    margin-bottom: 43px;
  }
  &__send-file {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
    @include lg {
      display: none;
    }
  }
  &__languages {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    @include lg {
      flex-direction: row;
      gap: 16px;
      margin-bottom: 0;
    }
  }
  &__continue-button {
    z-index: 1;
    border-radius: 30px;
  }
  &__form-top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    @include lg {
      margin-bottom: 56px;
      justify-content: space-between;
      position: relative;
    }
    .back {
      display: none;
      @include lg {
        display: flex;
      }
    }
    .step {
      display: none;
      @include lg {
        display: flex;
      }
    }
  }
  .container-right {
    justify-content: space-between;
  }
}
