.radio {
  &__input {
    display: none;
  }
  &__input-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    &--row {
      @include lg {
        flex-direction: row;
        gap: 16px;
      }
    }
  }
  &__label {
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: $black;
    display: flex;
    gap: 5px;
    align-items: center;
    color: black;
    margin-bottom: 9px;
  }
  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 17px 16px;
    border: 1px solid $gray800;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: black;
    max-height: 48px;
    transition: 0.3s;
    cursor: pointer;

    svg {
      transition: 0.3s;
      fill: transparent;
    }
    input:checked + .radio__icon-group .radio__checked-icon {
      opacity: 1;
    }
    input:checked + .radio__icon-group .svg--circle {
      opacity: 0;
    }
    &--selected {
      border: 1px solid #086852;
    }
    &--not-selected {
      border: 1px solid $gray700;
      color: $gray700;
    }
    &--row {
      @include lg {
        width: 100%;
      }
    }
  }
  &__icon-group {
    position: relative;
    height: 18px;
  }
  &__checked-icon {
    position: absolute;
    top: 9px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    opacity: 0;
  }
}
