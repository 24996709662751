.btn {
  box-sizing: border-box;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  border: none;
  background-color: $green500;
  color: $white;
  font-family: $primaryFont;
  font-weight: 800;
  gap: 8px;
  padding: 15px 20px 16px;
  cursor: pointer;
  width: 100%;
  transition:0.3s;
  &:hover{
    background-color: #22301C;
  }
  include lg{
      padding: 15px 120px 16px;
    }
  &--stroke {
    background-color: transparent;
    border: 2px solid $green500;
    color: $green500;
    &:hover{
      background-color: $green500;
      color: $white;
    }
  }
  &--disabled {
    background-color: $darkGray;
    pointer-events: none;
  }
}
