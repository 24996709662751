.service-levels {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  margin-inline: auto;
  width: 542px;
  align-items: center;
  &__wrapper {
    width: 100%;
    overflow-x: auto;
    padding: 43px 0;
    @include lg {
      padding: 53px 0;
    }
  }
  &__card {
    width: 176px;
    max-width: 176px;
    display: flex;
    flex-direction: column;
    background: $white;
    padding: 20px 17px 28px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    min-height: 283px;
    max-height: 283px;
    cursor: pointer;
    position: relative;
    &--center {
      min-height: 327px;
      max-height: 327px;
      .svg--recommendation {
        position: absolute;
        top: -20px;
        left: -11px;
        width: calc(100% + 22px);
        height: fit-content;
      }
      .service-levels__need-it-quicker {
        @include lg {
          @include goldGradientText;
        }
      }
      @include lg {
        min-height: 283px;
        max-height: 283px;
      }
    }
  }
  &__header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 24px;
    @include lg {
      margin-bottom: 32px;
    }
  }
  &__check-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    height: 16px;
    width: 16px;
    .svg--circle {
      position: absolute;
      top: 4px;
      left: 0;
      width: 16px;
      height: 16px;
      opacity: 1;
      &-hidden {
        opacity: 0;
      }
    }
    .svg--checked {
      position: absolute;
      top: 4px;
      left: 0;
      width: 16px;
      height: 16px;
      opacity: 0;
      &-visible {
        opacity: 1;
      }
    }
  }
  &__team {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
    flex: 1;
    @include body2;
  }
  &__team-item {
    display: flex;
    align-items: center;
    gap: 2px;
  }
  &__price {
    @include heading2;
    text-align: center;
    margin-bottom: 36px;
  }
  &__time {
    @include body2;
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: center;
  }
  &__date {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $text;
  }
  &__need-it-quicker {
    @include body2;
    @include mobile-only {
      @include goldGradientText;
    }
  }
  &__contact {
    display: flex;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    color: $text;
    align-items: center;
    gap: 24px;
    flex-direction: column;
    text-align: center;
    line-break: strict;
    margin-top: 3px;
    &-text {
      display: flex;
      gap: 3px;
      flex-direction: column;
      flex: 1;
      align-items: center;
      @include lg {
        flex-direction: row;
        gap: 5px;
      }
      p {
        width: fit-content;
       white-space: nowrap;
      }
    }
    @include lg {
      text-align: left;
      flex-direction: row;
      gap: 25px;
    }
    .btn {
      @include lg {
        max-width: 176px;
        max-height: 40px;
      }
    }
  }
  &--disabled{
    color: $disabled;
    path{
        fill: $disabled;
    }
  }
}
