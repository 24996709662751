.container-right {
  width: 100%;
  // max-width: 755px;
  padding: 27px 17px;
  background-color: $white;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  // gap: 24px;
  height: 678px;
  position: relative;
  @include lg {
    flex-basis: 369px;
  }
}
