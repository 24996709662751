.language-form {
  &__selected-languages {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    width: 100%;
    .select__multi-value {
      display: flex;
      align-items: center;
      gap: 8px;
      background: #f4f4f4;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    }
  }
  &__select {
    width: 100%;
    position: relative;
  }
  &__placeholder {
    position: absolute;
    top: 18px;
    left: 16px;
    color: $inputDefault;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    gap: 16px;
    visibility: visible;
    .svg path{
      stroke: $inputDefault;
    }
    &--hidden{
      visibility: hidden;
    }
  }
  &__modal {
    .select__value-container .select__multi-value {
      display: none;
    }
  }
  div .select__multi-value &__close {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  &__close-wrapper {
    display: flex;
    align-items: center;
  }
  &__language {
    display: flex;
    align-items: center;
    gap: 8px;
    // width: 100%;
    padding: 0 4px;
    cursor: pointer;
    @include body1;
    .svg--flag {
      width: 20px;
      height: 15px;
    }
  }
  &__most-popular {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  &__most-popular-languages {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }
  &__all-languages-language {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    cursor: pointer;
    height: 24px;
    max-width: calc(100% / 2 - 6px);
    @include lg {
      max-width: calc(100% / 3 - 8px);
    }
    @include body1;
    .svg--flag {
      width: 20px;
      height: 15px;
    }
  }
  &__all-languages-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    row-gap: 4.5px;
    width: 100%;
    flex-wrap: wrap;
    max-height: 192px;
    overflow-y: auto;
  }
  &__all-languages {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    flex-direction: column;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;
    margin-top: 24px;
    .btn {
      max-width: 175px;
      height: 40px;
      border-radius: 30px;
    }
  }
}
