.step{
    display: flex;
    gap: 4px;
    &__item{
        width: 24px;
        height: 8px;
        border-radius: 15px;
        background: $gray-7;
        transition: 0.3s;
        &--current{
            background: #086852;
        }
    }
}