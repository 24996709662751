@use '../../breakpoints.scss' as *;

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  height: fit-content;
  max-width: 751px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  padding: 36px 17px;
  @include lg {
    padding: 40px 94px;
  }
  
  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    &--open {
      opacity: 1;
      pointer-events: all;
    }
  }
}
