@mixin body1 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 138%;
  color: $text;
}

@mixin heading2 {
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  color: $text;
}

@mixin body2 {
  font-weight: 400;
  font-size: 10px;
  line-height: 134%;
  color: #7a7a7a;
}

@mixin goldGradientText {
  background: $goldGradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
