.container-left{
    width: 100%;
    padding: 27px 17px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // gap: 24px;
    @include lg{
        flex-basis: 755px;
        padding: 39px 96px;
    }
}