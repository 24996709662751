.input {
  background: $white;
  border: 1px solid $midGray;
  border-radius: 4px;
  padding: 15px 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  &::placeholder {
    color: $gray-2;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
  }
  &__label {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: $black;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  &__optional {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: $gray-1;
  }
  &__double-input {
    display: flex;
    gap: 10px;
    input:first-child {
      max-width: 30%;
    }
    input:last-child {
      max-width: 70%;
      
    }
  }
}
