@import './_reset';
@import './_variables';
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');

html {
  scroll-behavior: smooth;
}
body {
  line-height: 1.5;
  font-size: 16px;
  font-family: $primaryFont;
}
